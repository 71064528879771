'use client'; // Necesario para los componentes de cliente en Next.js

import React, { useState, useEffect } from 'react';

const Modal = () => {
  const [isOpen, setIsOpen] = useState(true);

  // Función para cerrar el modal
  const closeModal = () => {
    setIsOpen(false);
  };

  // Cerrar el modal cuando se hace clic fuera de él
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      closeModal();
    }
  };

  // Efecto para que el modal se abra al cargar la página
  useEffect(() => {
    setIsOpen(true);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOutsideClick} style={styles.overlay}>
      <div className="modal-content" style={styles.modal}>
        <button onClick={closeModal} style={styles.closeButton}>
          X
        </button>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/deliverica-c0c8d.appspot.com/o/roslindale.jpg?alt=media&token=243fc824-bd11-4602-b1ab-c93c3c80c0e6"
          alt="Promotional Image"
          style={styles.image}
        />
      </div>
    </div>
  );
};

// Estilos en línea
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  modal: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '90%', // Se ajusta al 90% de la pantalla
    maxWidth: '600px', // Tamaño máximo en ordenadores
    height: 'auto', // Se ajusta dinámicamente
    maxHeight: '80vh', // Altura máxima, 80% del viewport
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },
};

// Media query para dispositivos móviles
const mobileStyles = {
  '@media (max-width: 768px)': {
    modal: {
      width: '95%', // Más estrecho en móviles
      maxWidth: '90%',
      padding: '10px', // Menos padding en móviles
    },
    image: {
      maxHeight: '70vh', // La imagen puede ocupar el 70% de la altura de la pantalla en móviles
    },
  },
};

export default Modal;
